
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { auth, firestore } from '@/firebase';
import { browserLocalPersistence, signInWithEmailLink, isSignInWithEmailLink, signInWithEmailAndPassword, sendSignInLinkToEmail } from "firebase/auth";
import { getDoc, doc, query, collection, where, getDocs } from "firebase/firestore";
import { USER_ACTIONS } from "@/store/user/actions";
import { User } from "@/store/user/types";
import { httpsCallable } from "firebase/functions";
import { functions } from '@/firebase'
import axios from 'axios'
@Component
export default class Login extends Vue {
  user: null | User = null;

  loginLinkSent = false;
  completeLoginLoading = false;

  step = 1;
  inputs = {
    email: "",
    password: "",
  };

  loading = {
    submit: false,
  };

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [])
    if (isSignInWithEmailLink(auth, window.location.href)) {
      this.completeLogin()
    }
  }

  async completeLogin() {
    try {
      this.completeLoginLoading = true;
      let email = window.localStorage.getItem('emailForSignIn')
      if (email) {
        const authResponse = await signInWithEmailLink(auth, email, window.location.href)
        const userRef = doc(firestore, "users", authResponse.user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER)
          location.href = `/${userDoc.data().type.toLowerCase()}`
        }
      } else {
        email = window.prompt('Email?');
        const formattedMail = email?.trim().toLowerCase()
        const authResponse = await signInWithEmailLink(auth, formattedMail as string, window.location.href)
        const userRef = doc(firestore, "users", authResponse.user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER)
          location.href = `/${userDoc.data().type.toLowerCase()}`
        }
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: 'Er is iets misgelopen, probeer opnieuw.',
      });
    }

    this.completeLoginLoading = true;
  }

  async sendLoginEmail() {
    try {
      this.loading.submit = true;
      let email = this.inputs.email.trim().toLowerCase()
      // await sendSignInLinkToEmail(auth, email, {
      //   url: window.location.href,
      //   handleCodeInApp: true,
      // });
      await axios.post('/functions/sendLoginLink', { data: { email, url: window.location.href } })
      // await httpsCallable(functions, 'sendloginlink')({ email, url: window.location.href })
      this.loginLinkSent = true;
      this.loading.submit = false;
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.submit = false;
    }
  }


  async loginWithPassword() {
    try {
      this.loading.submit = true
      let email = this.inputs.email.trim().toLowerCase()
      const authResponse = await signInWithEmailAndPassword(auth, email, this.inputs.password)
      const userRef = doc(firestore, "users", authResponse.user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER)
        location.href = `/${userDoc.data().type.toLowerCase()}`
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    }
    this.loading.submit = false
  }

  async login() {
    this.loading.submit = true;
    try {

      let email = this.inputs.email.trim().toLowerCase()
      // set the login persistance
      await auth.setPersistence(browserLocalPersistence)
      window.localStorage.setItem('emailForSignIn', email)

      const userDocuments = await getDocs(query(collection(firestore, 'users'), where('email', '==', email)))

      if (userDocuments.empty) {
        throw 'Er is geen gebruiker met dit email adres gevonden.'
      }

      this.user = userDocuments.docs[0].data() as User

      if (!this.user.hasPassword) {
        await this.sendLoginEmail()
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.submit = false;
    }
  }
}
